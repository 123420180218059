// &trait=1
// &approved=true
import moment from "moment";
import { getFormattedTime } from "../../util/helper";

export const ENDPOINTS = {
  grid: (page, rowPerPage, filter) => {
    let url = `/v1/api/user/grid?sortBy=createdAt&sortAsc=-1&rows=` + rowPerPage + "&page=" + page;
    if (filter?.name) {
      url += `&fname=${filter?.name}`
    }
    if (filter?.fromJoinDate && filter?.tillJoinDate) {
      url += `&createdAt=btw[${getFormattedTime(filter?.fromJoinDate)},${getFormattedTime(filter?.tillJoinDate)}]`
    }
    if (filter?.fromLastContactDate && filter?.tillLastContactDate) {
      url += `&lastContact=btw[${getFormattedTime(filter?.fromLastContactDate)},${getFormattedTime(filter?.tillLastContactDate)}]`
    }
    if (filter?.phone) {
      url += `&phone=${filter?.phone}`
    }
    if (filter?.email) {
      url += `&email=caseIgnore[${filter?.email}]`
    }
    if (filter?.forDownload) {
      url += `&forDownload=${filter?.forDownload}`
    }
    if (filter?.testUser) {
      url += `&testUser=${filter?.testUser}`
    }
    return url
  },
  gridWalletByIds: `/v1/api/wallet/grid`,
  get: (id) => `/api/profile/get/customer/${id}`,
  editCustomer: (id) => `/api/profile/update/customer/${id}`,
  updateWallet: (customer_id) => `/api/v1/payments/user-wallet/topup?customer_id=${customer_id}`,
  save: `/api/offers/add`,
  getSpent: (id) => `/api/user/v1/spent/${id}`,
  getOrders: (id) => `/api/user/v1/orders/${id}`,
  getTimeSpent: (id) => `/api/user/v1/timespent/${id}`,
  getinvoice: (page, rowPerPage, id, filter) => {
    let url = `/api/profile/get/customer/paymentRecords/${id}?rowPerPage=` + rowPerPage + "&page=" + page
    if (filter?.fromDate) {
      url += `&fromDate=${filter?.fromDate}`
    }
    if (filter?.orderType) {
      url += `&chatype=${filter?.orderType}`
    }
    if (filter?.searchStatus) {
      url += `&status=${filter?.searchStatus}`
    }
    if (filter?.invoiceNo) {
      url += `&invoiceNo=${filter?.invoiceNo}`
    }
    if (filter?.tillDate) {
      url += `&tillDate=${filter?.tillDate}`
    }
    if (filter?.forDownload) {
      url += `&forDownload=${filter?.forDownload}`
    }
    return url
  },
  conversations: `/v1/api/conversation/grid`,
  analytics: `/v1/api/analytics/grid`,
  consultants: `/v1/api/consultant/grid`,
};

