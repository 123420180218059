import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { UncontrolledDropdown } from "reactstrap";
import "toastr/build/toastr.min.css";
import imageplaceholder from '../../assets/images/imagplaceholder.png'
import "../../components/Layout/style.css"
import {
  CardThreeBounce, itemBarSelectedStyle, Layout
} from "../../components";
import "../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ClientDetail from "./ClientDetail";
import ClientService from "./ClientService";
import moment from "moment";
import { milliSecondsToSeconds } from "../../util/helper";
import { FaEye } from "react-icons/fa";
import { formatSecondsToHMS } from "../../helpers/SupportServiceHelpers/utils";
import { BiSolidEdit } from "react-icons/bi";
import { ThreeBounce } from 'better-react-spinkit';

const Clients = (props) => {
  const { t } = useContext(I18nContext);
  const { id } = useParams();
  const { showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState(id);
  const [showDetailPage, setShowDetailPage] = useState(id);
  const [search, setSearch] = useState("")
  const [searchByPhone, setSearchByPhone] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [detail, setDetail] = useState({})
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [date, setDate] = useState({
    fromJoinDate: null,
    tillJoinDate: null,
    fromLastContactDate: null,
    tillLastContactDate: null,
  })
  const [userType, setUserType] = useState({
    label: "All Users",
    value: false,
  });
  // const [tillDate, setTillDate] = useState(null)
  let navigate = useNavigate();

  const [showViewPage, setShowViewPage] = useState(false)

  const getSpent = async (id) => {
    try {
      let response = {};
      if (id)
        response = await ClientService.getSpent(id)
      return response.spent;
    } catch (error) {
    }
  }

  const getOrders = async (id) => {
    try {
      let response = {};
      if (id)
        response = await ClientService.getOrders(id)
      return response.orders;
    } catch (error) {
    }
  }

  const getTimeSpent = async (id) => {
    try {
      let response = {};
      if (id)
        response = await ClientService.getTimeSpent(id)
      return response.timespent;
    } catch (error) {
    }
  }

  const fetchData = async ({ filterType = "", fromDate, tillDate, isDownload = false }) => {
    if (loading) return;
    setLoading(true);
    let filter = {}
    if (search) {
      filter.name = search
    }
    if (searchByPhone) {
      filter.phone = searchByPhone
    }
    if (searchEmail) {
      filter.email = searchEmail
    }
    if (userType.value) {
      filter.testUser = true
    }
    if (isDownload) {
      filter.forDownload = true
    }
    if ((filterType === "joinDate" && fromDate) || (date?.fromJoinDate || date?.tillJoinDate)) {
      filter.fromJoinDate = fromDate || date?.fromJoinDate;
      filter.tillJoinDate = tillDate || date?.tillJoinDate;
    }
    if ((filterType === "lastContactDate" && fromDate) || (date?.fromLastContactDate || date?.tillLastContactDate)) {
      filter.fromLastContactDate = fromDate || date?.fromLastContactDate;
      filter.tillLastContactDate = tillDate || date?.tillLastContactDate;
    }
    try {
      const response = await ClientService.fetch(filter)
      if (isDownload) {
        const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
        const link = document.createElement('a');
        link.href = csvContent;
        link.target = '_blank';
        link.download = 'customers.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        let tempData = response
        await Promise.all(tempData.map(async (client) => {
          client["amountSpent"] = await getSpent(client?._id);
          client["orders"] = await getOrders(client?._id);
          client["timeSpent"] = await getTimeSpent(client?._id);
        }));
        setData(tempData);
      }
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData({ filterType: "" });
  }, [ClientService?.page, ClientService?.rows, userType]);

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);
    setShowDetailPage(window.location.pathname === "/client/edit" || id);
  }, [id]);

  const getFullNameWithSalutation = (client) => {
    const salutation = client?.salutation;
    const name = client?.name;
    const fname = client?.fname;
    const lname = client?.lname
    if (salutation != null) {
      const salutationText = salutation === 0 ? t("Mr.") : t("Miss.");
      return (!fname && !lname) ? (name ? name : "--") : `${salutationText} ${fname ? fname : ''} ${lname ? lname : ""} `;
    } else {
      return (!fname && !lname) ? (name ? name : '--') : `${fname ? fname : ''} ${lname ? lname : ""}`
    }
  };

  const changePagination=()=>{
    if(ClientService.page!=1){
      ClientService.page=1;
    }
  }

  const userTypeOptions = [
    {
      icon: "",
      label: "All Users",
      value: false,
      callback: (e) => {
        changePagination();
        setUserType({
          ...userType,
          label: "All Users",
          value: false,
        })
      },
    },
    {
      icon: "",
      label: "Test Users",
      value: false,
      callback: (e) => {
        changePagination();
        setUserType({
          ...userType,
          label: "Test Users",
          value: true
        })
      },
    }
  ]

  // if (loading) return <CardThreeBounce />;
  return (
    <>
      <Layout
      changePagination={changePagination}
        download={true}
        filterUserType
        userType={userType?.label}
        userTypeOptions={userTypeOptions}
        hideTitleBar={true}
        filterInput={
          true
        }
        filterInput2={
          true
        }
        date={date}
        showJoinDate
        lastContactDatePlaceholder={["From Last Contact", "Till Last Contact"]}
        joinDatePlaceholder={["From Join Date", "Till Join Date"]}
        showLastContact
        search1={search}
        setSearch1={setSearch}
        search2={searchByPhone}
        setSearch2={setSearchByPhone}
        searchEmail={searchEmail}
        setSearchEmail={setSearchEmail}
        emailFilter={true}
        gridLoading={loading}
        fetchData={(d)=>{
          fetchData({...d, filterType: "" });
        }}
        showViewPage={showViewPage}
        page={ClientService.page}
        rows={ClientService.rows}
        insidePane={props?.insidePane}
        viewSection={<ClientDetail data={detail} />}
        total={ClientService.totalRecords}
        onDateChange={(title, fromDate, tillDate) => {
          if (title === "lastContactDate") {
            fetchData({ filterType: "lastContactDate", fromDate, tillDate })
            setDate({
              ...date,
              fromLastContactDate: fromDate,
              tillLastContactDate: tillDate
            })
          } else if (title === "joinDate") {
            fetchData({ filterType: "joinDate", fromDate, tillDate })
            setDate({
              ...date,
              fromJoinDate: fromDate,
              tillJoinDate: tillDate
            })
          }
        }}
        onPageChange={async (page, rows) => {
          await ClientService.onPaginationChange(page, rows);
        }}
      >

        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th style={{ verticalAlign: "middle" }}>{t("Phone no.")}</Th>
              <Th>{t("Email")}</Th>
              <Th>{t("Orders")}</Th>
              <Th>{t("Time Spent(Hrs)")}</Th>
              <Th>{t("Amount Spent")}</Th>
              <Th style={{ verticalAlign: "middle" }}>{t("Balance")}</Th>
              <Th>{t("Wallet (AED)")}</Th>
              <Th>{t("Last Contact")}</Th>
              <Th>{t("Join Date")}</Th>
              <Th className="float-end" style={{ verticalAlign: "middle" }}>{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody >
            {data?.map((client, idx) => (
              <Tr
                key={client?._id}
                style={{
                  pmarginLeft: "10px",
                  ...(id === client?._id ? itemBarSelectedStyle : {}),
                }}
                className={client?.testUser ? "table-striped-custom-row" : ""}
              >
                <Td>
                  <div className="mt-2">
                    {getFullNameWithSalutation(client)}
                  </div></Td>
                <Td>
                  <div className="mt-2">
                    {client?.phone ? `${client?.countryCode ? "+" + client?.countryCode : ""} ${client?.phone}` : "    --"}
                  </div>
                </Td>
                <Td>
                  <div style={{ fontSize: "12px" }} className="mt-2">
                    {client?.email}
                  </div>
                </Td>
                <Td>
                  {loading ? <div className={`d-flex align-items-center justify-content-center`} style={{ width: 100, height: 200, }}>
                    <ThreeBounce size={8} color="#9F73AB" />
                  </div> :
                    <div style={{ fontSize: "12px" }} className="mt-2">
                      {client?.orders ? client?.orders : "--"}
                    </div>}
                </Td>
                <Td>
                  <div style={{ fontSize: "12px" }} className="mt-2">
                    {client?.timeSpent ? formatSecondsToHMS(client?.timeSpent) : "--"}
                  </div>
                </Td>
                <Td>
                  <div style={{ fontSize: "12px" }} className="mt-2">
                    {client?.amountSpent ? client?.amountSpent : "--"}
                  </div>
                </Td>
                <Td>
                  <div className="mt-2">
                    {client?.balance ? client?.balance?.toFixed(2) : '    --'}
                  </div>

                </Td>
                <Td>{ClientService.walletBalanceMap[client?._id]?.toFixed(2) ?? '--'}</Td>
                <Td>
                  <div className="mt-2">
                    {client?.lastContact ? moment.unix(milliSecondsToSeconds(client?.lastContact)).format('DD-MM-YYYY') : "    --"}
                  </div>
                </Td>
                <Td>
                  <div className="mt-2">
                    {client?.createdAt ? moment.unix(milliSecondsToSeconds(client?.createdAt)).format('DD-MM-YYYY') : '--'}
                  </div>
                </Td>
                <Td>
                  <div className="d-flex align-items-center justify-content-between px-2 mt-2" style={{ fontSize: '14px' }}>
                    <div style={{ cursor: 'pointer' }} className="me-3" onClick={() =>
                      navigate(`/customer/edit/${client?._id}`)
                    }>
                      <BiSolidEdit size={18} />
                    </div>
                    <UncontrolledDropdown className="m-2">
                      <div style={{ cursor: 'pointer', fontSize: '14px' }} onClick={(e) => {
                        e.preventDefault()
                        setShowViewPage(true)
                        navigate(`/client/payment/${client?._id}`)
                        setDetail(client)
                      }
                      }>
                        <FaEye />
                      </div>
                    </UncontrolledDropdown>
                  </div>

                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout >

    </>
  );
};

export default observer(Clients);
