import { observer } from "mobx-react-lite";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Layout } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext.js";
import { I18nContext } from "../../store/context/i18nContext";
import ConsultantDetails from "./ConsultantDetails";
import ConsultantService from "./ConsultantService";
import Checkbox from "./Checkbox";
import "./style.css";
import image from "../../assets/images/imagplaceholder.png";
import ImageComponent from "../../components/Image/ImageComponent";
import { FaEye } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { Badge } from "reactstrap";
import { TbNotes } from "react-icons/tb";
import SummaryModal from "./SummaryModal.js";
import { doPOST } from "../../util/httpUtil.js";
import { ENDPOINTS } from "./ConsultantConstant.js";

const Consultant = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage, showAlert } = useContext(DialogContext);
  const { skills } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [disabledConsultants, setDisabledConsultants] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState(false);
  const [data, setData] = useState([]);
  const [searchconsultant, setSearchConsultant] = useState("");
  const [searchbyphone, setSearchbyphone] = useState("");
  const [joinDate, setJoinDate] = useState(null);
  const [country, setCountry] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchEmail, setSearchEmail] = useState("")
  const [date, setDate] = useState({
    fromJoinDate: null,
    tillJoinDate: null,
    fromLastContactDate: null,
    tillLastContactDate: null,
  })
  const [showViewPage, setShowViewPage] = useState(false);
  const [searchStatus, setSearchStatus] = useState({
    label: "Status",
    value: 0,
  });
  const [approvalStatus, setApprovalStatus] = useState({
    label: "Approval",
    value: 0,
  });
  const [skillType, setSkillType] = useState({
    label: "All Skills",
    value: "",
  });
  const [category, setCategory] = useState({
    label: "All",
    value: 0,
  });
  const [showSummary, setShowSummary] = useState({
    visible: false,
    id: ""
  });

  const fetchData = async ({ filterType = '', fromDate = null, tillDate = null, isDownload = false }) => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      if (searchconsultant) {
        filter.consultant = searchconsultant
      }
      if (searchbyphone) {
        filter.phone = searchbyphone
      }
      if (searchEmail) {
        filter.email = searchEmail
      }
      if ((filterType === "joinDate" && fromDate) || (date?.fromJoinDate || date?.tillJoinDate)) {
        filter.fromJoinDate = fromDate || date?.fromJoinDate;
        filter.tillJoinDate = tillDate || date?.tillJoinDate;
      }
      if ((filterType === "lastContactDate" && fromDate) || (date?.fromLastContactDate || date?.tillLastContactDate)) {
        filter.fromLastContactDate = fromDate || date?.fromLastContactDate;
        filter.tillLastContactDate = tillDate || date?.tillLastContactDate;
      }
      if (searchStatus.value) {
        filter.searchStatus = searchStatus?.value
      }
      if (approvalStatus.value) {
        filter.approvalStatus = approvalStatus?.value
      }
      if (country) {
        filter.country = country
      }
      if (searchCity) {
        filter.city = searchCity
      }
      if (category) {
        filter.category = category.value
      }
      if (skillType) {
        filter.skill = skillType.value
      }
      if (isDownload) {
        setLoading(false);
        filter.forDownload = true;
      }
      const response = await ConsultantService.fetch(filter);
      if (isDownload) {
        const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
        const link = document.createElement('a');
        link.href = csvContent;
        link.target = '_blank';
        link.download = 'consultants.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setData(response?.data?.rows);
        setSelectAll(false);
        response?.rows?.map((user, index) => {
          // if (user?.disable === true) {
          //   if (!disabledConsultants.includes(user?._id)) {
          //     disabledConsultants.push(user?._id);
          //   }
          // }
          // if (user?.disable === false) {
          //   setSelectAll(false);
          // }

          const updatedUser = {
            ...user,
            selected: false,
          };
          response.rows[index] = updatedUser;


        })
      }
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const bulkupload = async (file, templateDownload = false) => {
    const formData = new FormData();
    file != null && formData.append('file', file);

    try {
      const response = await doPOST(ENDPOINTS.bulkUpload(templateDownload), formData);
      if (response?.status == 200 && !templateDownload) {
        showMessage("success", "Upload SuccessFully")
        await fetchData({});
      }
      else if (response?.status == 200 && templateDownload) {
        const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
        const link = document.createElement('a');
        link.href = csvContent;
        link.target = '_blank';
        link.download = 'consultants_template_bulk.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log(error)
      showError(error)
    }
  };


  useEffect(() => {
    fetchData({ filterType: '' });
  }, [ConsultantService?.rows, ConsultantService?.page, searchStatus, skillType, approvalStatus, category]);


  let navigate = useNavigate();

  const bulkUpdate = async (update_event) => {
    const selected_ids = data.filter((e) => (e.selected)).map(e => e._id);

    if (!selected_ids?.length) {
      showError("Please select consultants");
      return
    }

    if (update_event === "disable") {
      const isConfirm = await showConfirm({
        title: "Unapprove Consultant?",
        description: "Are you sure you want to Unapprove this Consultant?",
      })
      if (!isConfirm) return;
    } else if (update_event === "enable") {
      const isConfirm = await showConfirm({
        title: "Approve Consultant?",
        description: "Are you sure you want to approve this Consultant?",
      })
      if (!isConfirm) return;
    }

    const body = {
      event: update_event,
      consultant_ids: selected_ids.toString()
    }
    try {
      await ConsultantService.bulkUpdate(body);
      setSelectAll(false);
      showMessage("success", "Updated  Successfully");
      fetchData({ filterType: '' });
    } catch (e) {
      showError(e);
    }
  };

  const deleteBulk = async () => {
    const ids = data?.filter(e => e?.selected)?.map(e => e?._id);
    if (loading) return;
    try {
      if (await showConfirm({
        title: "Delete Consultants?",
        description: "Are you sure you want to delete selected Consultants?",
      })) {
        setLoading(true);
        const res = await ConsultantService.bulkDelete(ids);
        if (res) {
          showAlert({
            title: "Consultants deleted successfully",
            description: "",
          });
        }
      }
      await fetchData({ filterType: '' });
    } catch (error) {
      showError(error?.message);
    } finally {
      setLoading(false);
    }
  }


  const actionOptions = [
    {
      icon: "",
      label: "Bulk Enable",
      value: "enable",
      callback: bulkUpdate,
    },
    {
      icon: "",
      label: "Bulk Disable",
      value: "disable",
      callback: bulkUpdate,
    },
    {
      icon: "",
      label: "Bulk Delete",
      value: "delete",
      callback: deleteBulk,
    }
  ]

  const StatusOptions = [
    {
      icon: "",
      label: "All",
      value: 0,
      callback: () => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "All",
          value: 0,
        })
      },
    },
    {
      icon: "",
      label: "Disabled",
      value: 1,
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Disabled",
          value: 1
        })
      },
    },
    {
      icon: "",
      label: "Enabled",
      value: 2,
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Enabled",
          value: 2
        })
      },
    },
  ]
  const ApprovalOptions = [
    {
      icon: "",
      label: "All",
      value: 0,
      callback: () => {
        changePagination();
        setApprovalStatus({
          ...approvalStatus,
          label: "All",
          value: 0,
        })
      },
    },
    {
      icon: "",
      label: "Approved",
      value: 1,
      callback: (e) => {
        changePagination();
        setApprovalStatus({
          ...approvalStatus,
          label: "Approved",
          value: 1
        })
      },
    },
    {
      icon: "",
      label: "Dissaproved",
      value: 2,
      callback: (e) => {
        changePagination();
        setApprovalStatus({
          ...approvalStatus,
          label: "Dissaproved",
          value: 2
        })
      },
    },
  ]

  const changePagination = () => {
    if (ConsultantService.page != 1) {
      ConsultantService.page = 1;
    }
  }

  const userTypeOptions = [
    {
      label: "All Skills",
      value: "",
      callback: (e) => {
        changePagination();
        setSkillType({
          ...skillType,
          label: "All Skills",
          value: "",
        })
      },
    },
    ...skills?.map(e => ({
      label: e?.name,
      value: e?.name,
      callback: (e) => {
        changePagination();
        setSkillType({
          ...skillType,
          label: e,
          value: e,
        })
      },
    })),
  ]

  const categoryOptions = [
    {
      label: "All",
      value: 0,
      callback: (e) => {
        changePagination();
        setCategory({
          ...category,
          label: "All",
          value: 0,
        })
      },
    },
    {
      label: "Chat",
      value: 1,
      callback: (e) => {
        changePagination();
        setCategory({
          ...category,
          label: "Chat",
          value: 1,
        })
      },
    },
    {
      label: "Call",
      value: 2,
      callback: (e) => {
        changePagination();
        setCategory({
          ...category,
          label: "Call",
          value: 2,
        })
      },
    },
    {
      label: "Video Call",
      value: 3,
      callback: (e) => {
        changePagination();
        setCategory({
          ...category,
          label: "Video Call",
          value: 3,
        })
      },
    },
  ]

  return (
    <>
      <Layout
        changePagination={changePagination}
        download={true}
        bulkUpload={true}
        bulkDelete={true}
        onBulkDelete={deleteBulk}
        onBulkUpload={(file, templateDownload) => {
          bulkupload(file, templateDownload)
        }}
        hideTitleBar={true}
        filterInput={true}
        filterInput2={true}
        filterStatus
        filterUserType
        userType={skillType?.label}
        userTypeOptions={userTypeOptions}
        filterCategoryType={true}
        categoryType={category.label}
        userCategoryOptions={categoryOptions}
        filterApproval
        filterCountry
        filterCity
        showJoinDate
        joinDate={joinDate}
        date={date}
        setJoinDate={setJoinDate}
        joinDatePlaceholder={["From Join Date", "Till Join Date"]}
        searchCity={searchCity}
        setSearchCity={setSearchCity}
        country={country}
        setCountry={setCountry}
        statusAction={StatusOptions}
        search1={searchconsultant}
        search2={searchbyphone}
        searchEmail={searchEmail}
        searchStatus={searchStatus.label}
        approvalStatus={approvalStatus.label}
        approvalAction={ApprovalOptions}
        setSearchEmail={setSearchEmail}
        setSearch2={setSearchbyphone}
        setSearch1={setSearchConsultant}
        emailFilter={true}
        actions={actionOptions}
        fetchData={(d) => {
          fetchData({ ...d, filterType: '' });
        }}
        onAddClick={() => {
          navigate("/consultant/edit");
        }}
        addcontent={"Consultant"}
        disableMultiple={"Disable"}
        gridLoading={loading}
        rows={ConsultantService.rows}
        total={ConsultantService.totalRecords}
        page={ConsultantService.page}
        showViewPage={showViewPage}
        viewSection={<ConsultantDetails />}
        onDateChange={(title, fromDate, tillDate) => {
          if (title === "lastContactDate") {
            fetchData({ filterType: "lastContactDate", fromDate, tillDate })
            setDate({
              ...date,
              fromLastContactDate: fromDate,
              tillLastContactDate: tillDate
            })
          } else if (title === "joinDate") {
            fetchData({ filterType: "joinDate", fromDate, tillDate })
            setDate({
              ...date,
              fromJoinDate: fromDate,
              tillJoinDate: tillDate
            })
          }
        }}
        onPageChange={async (page, rows) => {
          await ConsultantService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>
                <Checkbox
                  checked={selectAll}
                  onClick={(v) => {
                    if (!selectAll) {
                      data.forEach(user => {
                        user.selected = true;
                      });
                    } else {
                      data.forEach(user => {
                        user.selected = false;
                      });
                    }
                    setSelectAll(!selectAll);
                    setData(data)
                  }}
                />
              </Th>
              <Th style={{ textAlign: 'center' }}>{t("Name")}</Th>
              <Th>{t("Display Name")}</Th>
              <Th>{t("Country")}</Th>
              <Th>{t("City")}</Th>
              <Th style={{ verticalAlign: "middle" }}>{t("Status")}</Th>
              <Th style={{ verticalAlign: "middle" }}><div>{t("Email")}
              </div></Th>
              <Th>{t("Mobile No.")}</Th>
              <Th>{t("Join date")}</Th>
              <Th>{t("Last Online At")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody style={{ maxHeight: "100px", overflowY: "scroll" }}>
            {data?.map((user, index) => (
              <Tr key={user?._id}>
                <Td style={{ width: "1px" }} >
                  <Checkbox
                    checked={data[index].selected}
                    onClick={() => {
                      if (data[index].selected) setSelectAll(false);
                      setData((prevData) => {
                        const newData = [...prevData];
                        newData[index] = {
                          ...newData[index],
                          selected: !newData[index].selected,
                        };
                        return newData;
                      });
                    }}

                  />
                </Td>
                <Td>
                  <div className="d-flex ">
                    <ImageComponent style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "30px"
                    }} source={user?.profileImage
                      ? `${user?.profileImage}`
                      : image} />
                    <div className="px-4 d-flex align-items-center">
                      {user?.isApproved === true ? (
                        <span style={{ color: 'green', marginRight: '6px', fontSize: '20px' }}>●</span>
                      ) : (
                        <span style={{ color: 'red', marginRight: '6px', fontSize: '20px' }}>●</span>
                      )}
                    </div>
                    <div className="px-2 mt-2">{user?.fname} {user?.lname}</div>
                  </div>
                </Td>
                <Td >
                  <div className=" mt-2 ">
                    {user?.displayName}
                  </div>
                </Td>
                <Td >
                  <div className=" mt-2 ">
                    {user?.country}
                  </div>
                </Td>
                <Td >
                  <div className=" mt-2 ">
                    {user?.city}
                  </div>
                </Td>
                <Td>
                  <div className="mt-2">
                    <Badge className={`rounded-pill ${user?.disable == true ? "bg-danger" : "bg-success"} `} style={{ "padding": "6px" }}>
                      {user?.disable === true ? "Disabled" : "Enabled"}
                    </Badge>
                  </div>
                </Td>
                <Td >
                  <div className=" mt-2 ">
                    {user?.email}
                  </div>
                </Td>
                <Td>
                  <div className="mt-2">
                    {user.countryCode ? "+" + user.countryCode : ""}{" "}
                    {user?.phone ? user?.phone : "-"}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {user?.createdAt ? moment.unix(user?.createdAt).format("DD-MM-YYYY HH:mm") : "--"}
                  </div>
                </Td>
                <Td >{user?.lastOnlineAt ? moment.unix(user?.lastOnlineAt).format("DD-MM-YYYY HH:mm") : "--"}</Td>
                <Td className="float-start justify-content-between">
                  <div className="d-flex align-items-center justify-content-between px-2 mt-2" style={{ fontSize: '14px' }}>
                    <div style={{ cursor: 'pointer' }} className="me-2" onClick={() =>
                      navigate(`/consultant/edit/${user?._id}`)
                    }>
                      <BiSolidEdit size={18} />
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                      setShowViewPage(true);
                      navigate(`/consultant/payment/${user?._id}`);
                    }}>
                      <FaEye size={16} />
                    </div>
                    <div className="ms-2" style={{ cursor: 'pointer' }} onClick={() => {
                      setShowSummary({
                        visible: true,
                        id: user?._id
                      });
                    }}>
                      <TbNotes size={16} />
                    </div>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
      <SummaryModal modalIsOpen={showSummary.visible} setModalIsOpen={setShowSummary} id={showSummary.id} />
    </>
  );
};

export default observer(Consultant);
