import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import CustomerPayment from "../pages/Clients/ClientInvoice";
import Clients from "../pages/Clients/Clients";
import Consultant from "../pages/Consultant/Consultant";
import ConsultantPayment from "../pages/Consultant/ConsultantPayment";
import Dashboard from "../pages/Dashboard/index";
import Finance from "../pages/Finance/Finance";
import EditOffer from "../pages/Offers/EditOffer";
import Offer from "../pages/Offers/Offer";
import EditSupervisor from "../pages/Supervisor/EditSupervisor";
import Roles from "../pages/Supervisor/Roles";
import Supervisor from "../pages/Supervisor/Supervisor";
import Support from "../pages/Support/Support";

import Configuration from "../pages/Configuration/Configuration";
// import EditConfiguration from "../pages/Configuration/EditConfiguration";
import CurrencyRate from "../pages/CurrencyRate/CurrencyRate";
import EditCurrencyRate from "../pages/CurrencyRate/EditCurrencyRate";

import ForgetGetPassword from "../pages/Authentication/ForgetPassword";
import EditConsultant from "../pages/Consultant/EditConsultant";
import Event from "../pages/Events/Event";
import Notification from "../pages/ManageNotifications/Notification";
import Review from "../pages/Reviews/Review";
import supportDetail from "../pages/Support/supportDetail";


import Banner from "../pages/Banner/Banner";
import EditBanner from "../pages/Banner/EditBanner";
import EditReviews from "../pages/Reviews/EditReviews";
import MyTransaction from "../pages/MyTransaction/MyTransaction";
import EditClient from "../pages/Clients/EditClient";

const userRoutes = [
  { path: "/dashboard", Component: Dashboard },
  { path: "/support", Component: Support },
  { path: "/supportdetail/:id/:ticket_id", Component: supportDetail },
  { path: "/customer", Component: Clients },
  { path: "/customer/edit/:id", Component: EditClient },
  { path: "/customer/edit/", Component: Clients },
  { path: "/supervisor", Component: Supervisor },
  { path: "/supervisor/edit", Component: EditSupervisor },
  { path: "/supervisor/edit/:id", Component: EditSupervisor },
  { path: "/event", Component: Event },


  { path: "/roles", Component: Roles },
  { path: "/consultant", Component: Consultant },
  { path: "/consultant/edit", Component: EditConsultant },
  { path: "/consultant/edit/:id", Component: EditConsultant },

  { path: "/banner", Component: Banner },
  { path: "/banner/edit", Component: EditBanner },
  { path: "/banner/edit/:id", Component: EditBanner },

  // { path: "/notification", Component: Notification },
  // { path: "/notification/edit", Component: Notification },
  // { path: "/notification/edit/:id", Component: Notification },

  { path: "/notification", Component: Notification },

  { path: "/review", Component: Review },
  { path: "/review/edit", Component: EditReviews },
  { path: "/review/edit/:id", Component: EditReviews },


  { path: "/finance", Component: Finance },
  { path: "/my-transaction", Component: MyTransaction },
  { path: "/offer", Component: Offer },
  { path: "/offer/edit", Component: EditOffer },
  { path: "/offer/edit/:id", Component: EditOffer },


  { path: "/configuration", Component: Configuration },
  // { path: "/configuration/edit", Component: EditConfiguration },
  // { path: "/configuration/edit/:name", Component: EditConfiguration },

  { path: "/currencyRate", Component: CurrencyRate },
  { path: "/currencyRate/edit", Component: EditCurrencyRate },
  { path: "/currencyRate/edit/:name", Component: EditCurrencyRate },






  { path: "/consultant/payment/:id", Component: ConsultantPayment },
  { path: "/client/payment/:id", Component: CustomerPayment },

  {
    path: "/dashboard",
    exact: true,
    Component: () => {

      return <Navigate to="/dashboard" />;

    },
  },
  // {
  //   path: "/",
  //   exact: true,
  //   Component: () => {
  //     if (localStorage.getItem("token")) {
  //       return <Navigate to="/dashboard" />;
  //     }
  //     return <Navigate to="/signup" />;
  //   },
  // },
];

const authRoutes = [
  { path: "/", Component: Login },
  { path: "/forgot-password", Component: ForgetGetPassword },

  { path: "/logout", Component: Logout },
  // {
  //   path: "/signup",
  //   Component: () => {
  //     if (localStorage.getItem("token")) {
  //       return <Navigate to="/dashboard" />;
  //     }
  //     return <SignUp />
  //   }
  // },
  {
    path: "/login",
    Component: () => {
      if (localStorage.getItem("token")) {
        return <Navigate to="/dashboard" />;
      }
      return <Login />;
    },
  },
];

export { authRoutes, userRoutes };

